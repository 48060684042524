<script>
import { mapGetters } from "vuex";
import { stringSplit } from "@/utils/helpers";
import _filter from "lodash/filter";
export default {
  props: {
    locations: {
      default: "",
      type: String,
    },
  },

  computed: {
    ...mapGetters("config", ["config_locations_full"]),
    selected() {
      let terms = [];
      let raw = stringSplit(this.locations, ",");
      for (let index = 0; index < raw.length; index++) {
        terms.push(Number(raw[index]));
      }
      let locations = _filter(this.config_locations_full, loc => {
        return terms.indexOf(loc.loc_id) > -1;
      });
      return locations;
    },
    inputText() {
      let sel = this.selected;
      let text = "";
      if (sel.length === 1) {
        text += `${sel[0].loc_name_long}`;
      } else if (sel.length > 1) {
        if (sel[0]) text += `${sel[0].loc_name_short}`;
        if (sel[1]) text += `, ${sel[1].loc_name_short}`;
        if (sel[2]) text += ` y ${sel.length - 2} más...`;
      }
      return text;
    },
  },

  methods: {
    go() {
      this.$router.push({
        name: "finder-filter-locations",
        query: this.$route.query,
      });
    },
  },
};
</script>

<template>
  <div id="filterToggle--locations">
    <span class="sicon-search"></span>
    <input
      :value="inputText"
      type="text"
      class="input"
      placeholder='Filtra ubicaciones, ej: "santo domingo"'
    />
    <div class="clickarea" @click="go"></div>
  </div>
</template>

<style lang="scss" scoped>
#filterToggle--locations {
  position: relative;
  .clickarea {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .sicon-search,
  .input {
    height: 2.75rem;
  }
  .sicon-search {
    position: absolute;
    z-index: 1;
    left: 0.375rem;
    width: 2.25rem;
  }
  .input {
    padding: 0 1rem;
    padding-left: 2.625rem;
    border: 1px solid #dbdbdb;
    border-radius: 1.5rem;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 3rem;
      height: 2.75rem;
      top: 0;
      right: 1rem;
      background-color: #f2f2f2;
      background: linear-gradient(
        90deg,
        rgba(242, 242, 242, 0) 0%,
        rgba(242, 242, 242, 1) 100%
      );
    }
  }
}
</style>
